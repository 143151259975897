<template>
  <div>
    <div>
      <b-container>
        <b-row class="mb-4" style="margin-top:8%">
          <b-col>
            <b-input-group size="sm">
              <b-form-input
                @keyup.enter="Refresh"
                v-model="filter.search"
                :placeholder="$t('search')"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @keyup.prevent.enter="Refresh"
                  @click="Refresh"
                >
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <b-container class="m-4 p-4">
        <b-row>
          <b-col
            cols="12"
            md=""
            v-for="(item, index) in schoollist"
            :key="index"
          >
            <b-card class="mb-3" style="min-height: 400px">
              <h2 class="mb-1">
                {{ $t("SchoolInfo") }}
              </h2>
              <table>
                <tbody>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("oblast") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.oblast }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("region") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.region }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("fullname") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.fullname }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("schooltype") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.schooltype }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("director") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.director }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("contactinfo") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.contactinfo }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("cashier") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.cashier }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("adress") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.adress }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1 pb-1">{{ $t("zipcode") }}:</td>
                    <td class="pb-1">
                      <span class="font-weight-bold">
                        {{ item.zipcode }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-row class="mt-2">
      <b-col>
        <div class="d-flex justify-content-between">
          <div>
            <span class="mr-2">
              {{ firstNumber }} - {{ lastNumber }} of {{ totalRows }} items
            </span>
            <b-form-select
              size="sm"
              style="width : 60px"
              v-model="filter.PageLimit"
              :options="filter.pageOptions"
            ></b-form-select>
          </div>
          <b-pagination
            size="sm"
            v-model="filter.PageNumber"
            :total-rows="totalRows"
            :per-page="filter.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import HelperService from "@/services/Helper.service";
export default {
  data() {
    return {
      isBusy: false,
      items: {},
      totalRows: "",
      schoollist: [],
      filter: {
        search: "",
        SortColumn: "",
        OrderType: "asc",
        PageNumber: 1,
        PageLimit: 20,
        pageOptions: ["10", "20", "50", "100"],
      },
    };
  },
  created() {
    // HelperService.GetAllSchools(this.filter).then((res) => {
    //   this.schoollist = res.data;
    // });
    this.Refresh();
  },
  computed: {
    firstNumber() {
      return (this.filter.PageNumber - 1) * this.filter.PageLimit + 1;
    },
    lastNumber() {
      if (this.totalRows < this.filter.PageLimit) {
        return this.totalRows;
      } else {
        if (this.filter.PageNumber * this.filter.PageLimit > this.totalRows) {
          return this.totalRows;
        } else {
          return this.filter.PageNumber * this.filter.PageLimit;
        }
      }
    },
  },
  methods: {
    Refresh() {
      HelperService.GetAllSchools(
        this.filter.search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.PageNumber,
        this.filter.PageLimit
      ).then((res) => {
        this.schoollist = res.data.rows;
        // this.items = this.GetAllSchools;
        this.totalRows = res.data.total;
      });
    },
  },
};
</script>
<style></style>
